/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Button } from "reactstrap";
import { Countdown } from "../Countdown/Countdown.js"
import moment from 'moment'
import 'moment-timezone'

import './PageHeader.scss';

export default function PageHeader() {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const showerDate = moment("2021-08-07T10:30:00-0700", "YYYY-MM-DDTHH:mm:ssZ").toDate(); 

  const offset = new Date();
  const remainingTime = showerDate - offset;
  const days = remainingTime / 86400 / 1000;

  const scrollToRsvp = () => {
    document
      .getElementById("signup-section")
      .scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="page-header header-filter">
      <div className="squares square1" />
      <div className="squares square2" />
      <div className="squares square3" />
      <div className="squares square4" />
      <div className="squares square5" />
      <div className="squares square6" />
      <div className="squares square7" />
      <Container className="bump center">
        <div className="content-center brand">
          <h1 className="h1-seo">Notorious E.G.B.</h1>
          <h3>
            A Baby Shower for<br />
            Baby Girl Burch<br />
            <br />
            {moment.tz(showerDate, timezone).format('MMMM Do, YYYY')}<br />
            {moment.tz(showerDate, timezone).format('HH:mm:ss A (z)')}
          </h3>
          <div className="countdown">
            <Countdown value={days} />
          </div>
          <div>
            <br />
            <Button className="btn-round" color="primary" size="lg" onClick={scrollToRsvp}>
              RSVP Now
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
}
