/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Alert
} from "reactstrap";
import moment from 'moment'
import axios from 'axios'

export default function Rsvp() {
  const [fullNameFocus, setFullNameFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [phoneFocus, setPhoneFocus] = React.useState(false);
  const [addressFocus, setAddressFocus] = React.useState(false);
  const [nameGuessFocus, setNameGuessFocus] = React.useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [disabled, toggleDisabled] = React.useState(false);

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const showerDate = moment("2021-08-07T10:30:00-0700", "YYYY-MM-DDTHH:mm:ssZ").toDate(); 

  const postRsvp = async () => {
    toggleDisabled(true);
    setShowSuccess(false);
    setShowError(false);
    let data = {
      fullName: document.getElementById('fullName').value,
      email: document.getElementById('email').value,
      phone: document.getElementById('phone').value,
      address: document.getElementById('address').value,
      nameGuess: document.getElementById('nameGuess').value,
      agree: document.getElementById('agree').checked
    };

    if (document.getElementById('fullName').value === "")
    {
      alert("You must enter your name");
      toggleDisabled(false);
      return;
    }
    if (document.getElementById('email').value === "")
    {
      alert("You must enter your email");
      toggleDisabled(false);
      return;
    }
    if (document.getElementById('phone').value === "")
    {
      alert("You must enter your phone");
      toggleDisabled(false);
      return;
    }

    axios({
      method: 'post',
      url: "https://alcs1igbv3.execute-api.us-west-2.amazonaws.com/default/egb-rsvp-lambda-post",
      headers: { "content-type": "application/json" }, 
      data: data
    })
      .then(function(response)
      {
        setShowSuccess(true);
        toggleDisabled(false);
      })
      .catch(function(error)
      { 
        setShowError(true);
        console.log(error);
        toggleDisabled(false);
      });
  }

  return (
    <div className="section section-signup" id="signup-section">

      <Container>
        <div className="squares square-1" />
        <div className="squares square-2" />
        <div className="squares square-3" />
        <div className="squares square-4" />
        <Row className="row-grid justify-content-between align-items-center">
          <Col lg="6">
            <h3 className="display-3 text-white">
              You're invited!
            </h3>
            <p className="text-white mb-3">
              Join Amanda and Joe on {moment.tz(showerDate, timezone).format('MMMM Do, YYYY @ HH:mm:ss A (z) ')}
              to celebrate the impending arrival of Baby Girl Burch! We hope you can join us.
            </p>
          </Col>
          <Col className="mb-lg-auto" lg="6">
            <Card className="card-register">
              <CardHeader>
                <CardTitle tag="h4">RSVP</CardTitle>
              </CardHeader>
              <CardBody>
                <Form className="form">
                  <InputGroup
                    className={classnames({
                      "input-group-focus": fullNameFocus,
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-single-02" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      id="fullName"
                      placeholder="Full Name"
                      type="text"
                      onFocus={(e) => setFullNameFocus(true)}
                      onBlur={(e) => setFullNameFocus(false)}
                    />
                  </InputGroup>
                  <InputGroup
                    className={classnames({
                      "input-group-focus": emailFocus,
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-email-85" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      id="email"
                      placeholder="Email"
                      type="email"
                      onFocus={(e) => setEmailFocus(true)}
                      onBlur={(e) => setEmailFocus(false)}
                    />
                  </InputGroup>
                  <InputGroup
                    className={classnames({
                      "input-group-focus": phoneFocus,
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-mobile" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      id="phone"
                      placeholder="Phone"
                      type="phone"
                      onFocus={(e) => setPhoneFocus(true)}
                      onBlur={(e) => setPhoneFocus(false)}
                    />
                  </InputGroup>
                  <InputGroup
                    className={classnames({
                      "input-group-focus": addressFocus,
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-badge" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      id="address"
                      placeholder="Address (for prizes and/or thank you notes)"
                      type="textarea"
                      onFocus={(e) => setAddressFocus(true)}
                      onBlur={(e) => setAddressFocus(false)}
                    />
                  </InputGroup>
                  <InputGroup
                    className={classnames({
                      "input-group-focus": nameGuessFocus,
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-heart-2" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      id="nameGuess"
                      placeholder="Baby Name Guess: E _ _ _ _ _  G _ _ _  BURCH"
                      type="text"
                      onFocus={(e) => setNameGuessFocus(true)}
                      onBlur={(e) => setNameGuessFocus(false)}
                    />
                  </InputGroup>
                  <FormGroup check className="text-left">
                    <Label check>
                      <Input type="checkbox" id="agree" />
                      <span className="form-check-sign" />I agree to have all my information
                      sold for third-party marketing purposes pursuant to the terms of attending
                      the baby shower. (Haha!)
                    </Label>
                  </FormGroup>
                </Form>
              </CardBody>
              <CardFooter>

              { showSuccess ? 
                <Alert className="alert-with-icon" color="success">
                  <span data-notify="icon" className="tim-icons icon-bell-55" />
                  <span>
                    <b>RSVP Sent--</b>
                    We look forward to <i>seeing</i> you soon!
                  </span>
                </Alert> : null 
                }
              { showError ? 
                <Alert className="alert-with-icon" color="danger">
                  <span data-notify="icon" className="tim-icons icon-bell-55" />
                  <span>
                    <b>Oh no--</b>
                    we couldn't send your RSVP. Please contact Amanda or Joe!
                  </span>
                </Alert> : null 
                }
                <Button className="btn-round" color="primary" size="lg" onClick={postRsvp} disabled={disabled}>
                  RSVP
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
