/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Jumbotron,
  Container,
  Row,
  Col
} from "reactstrap";
import "./Info.scss";

import moment from 'moment'

export default function Info() {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const showerDate = moment("2021-08-07T10:30:00-0700", "YYYY-MM-DDTHH:mm:ssZ").toDate(); 

  return (
    <div className="section section-basic" id="basic-elements">
      <Container>
        <h2>What, When, Where, Why?</h2>
        <Row>
          <Col>
            <Jumbotron>
              <Container>
                <Row>
                  <Col md="6" sm="12">
                    <p><b>What:</b> Join us for a virtual baby shower including:</p>
                    <ul>
                      <li>Games</li>
                      <li>Prizes</li>
                      <li>Baby Girl Burch's Name</li>
                      <li>Gift Opening</li>
                    </ul>
                  </Col>
                  <Col md="6" sm="12">
                    <p><b>When:</b> {moment.tz(showerDate, timezone).format('MMMM Do, YYYY @ HH:mm:ss A (z)')}</p>
                    <p><b>Where:</b> Virtual via Zoom (Link to be shared via email before the event!)</p>
                    <p><b>Why:</b> Because we're awesome, having a baby, and you want to hang out with us!</p>
                  </Col>

                </Row>
                <Row className="center">
                  <Col>
                    <p>
                      <br />
                      We're happy to just spend time with everyone, but if you feel the need to buy a gift, we're registered at BabyList:
                    </p>
                  </Col>
                </Row>
                <Row className="center">
                  <Col>
                    <p>
                      <a href="https://www.babylist.com/baby-reg-amanda-killeen" target="_babylist">
                        <img src={require("assets/img/babylist.webp").default} width="250" alt="babylist" />
                      </a>
                    </p>
                  </Col>
                </Row>
              </Container>
            </Jumbotron>
          </Col>
        </Row>
        <h2>Recommended Libations</h2>
        <Row>
          <Col lg="6" md="12">
            <Jumbotron className="drink1">
              <h3>The Amandahattan</h3>
              <Container>
                <Row>
                  <Col>
                    <img src={require("assets/img/Perfect_Manhattan-4774-1.jpeg").default} alt="The Amandahattan"/>
                    <br /><br />
                    <h4>Ingredients</h4>
                    <ul>
                      <li>2 oz Bourbon</li>
                      <li>1 oz Dry Vermouth</li>
                      <li>16 drops Bohemian Bitters Aromatic Bitters</li>
                      <li>8 drops Spiced Cherry Bitters</li>
                      <li>8 drops Orange Bitters</li>
                      <li>Garnish: 
                          <ul>
                            <li>Orange Peel</li>
                            <li>Marischino or Bada Bing Cherry</li>
                          </ul>
                        </li>
                    </ul>
                    <h4>Directions</h4>
                    <ol>
                      <li>Combine Vermouth, Bourbon and Bitters in a mixing glass 
                        with ice.</li>
                      <li>Stir until well-chilled and strain into a glass over 
                        a large ice cube.</li>
                      <li>Express the oils from an Orange 
                        Peel over the cocktail and garnish with the peel.</li>
                      <li>Add a Cherry if you like.</li>
                    </ol>
                    <p>The Amandahattan will be slightly modified for Amanda,
                      who will be making a non-alcoholic version using {" "}
                      <a href="https://www.ritualzeroproof.com/products/ritual-whiskey-alternative" target="_ritual">
                        Ritual Whiskey Alternative
                      </a> with no vermouth or bitters. Maybe we should just 
                      call it a faux bourbon and cherry?
                    </p>
                  </Col>
                </Row>
              </Container>
            </Jumbotron>
          </Col>
          <Col lg="6" md="12">
            <Jumbotron className="drink2">
              <h3>The Joejito</h3>
              <Container>
                <Row>
                  <Col>
                    <img src={require("assets/img/passion-fruit-mojito-recipe-photo-1.jpeg").default} alt="The Joejito" />
                    <br /><br />
                    <h4>Ingredients</h4>
                    <ul>
                      <li>2 Fresh Passion Fruit (or 3 Tbsp frozen)</li>
                      <li>5-6 Fresh Mint Leaves, plus extra for garnish</li>
                      <li>1 tablespoons Simple Syrup (equal parts water and sugar boiled until clear--cooled)</li>
                      <li>1.5 ounces Light Rum</li>
                      <li>2 tablespoons Lime Juice (fresh)</li>
                      <li>4 - 5 Ice Cubes, plus more for glass</li>
                      <li>Club Soda</li>
                    </ul>
                    <h4>Directions</h4>
                    <ol>
                      <li>Cut open the Passion Fruit and push through a fine mesh strainer to 
                        catch all the juice, press hard against the Passion Fruit to also 
                        catch the pulp. Get as much juice and pulp from the Passion Fruit as possible. 
                        Reserve the seeds. Set the juice aside.</li>
                      <li>In a cocktail mixer, muddle the Mint to help 
                        release the oils from the Mint. Add the Rum, Lime Juice, Simple Syrup and Ice, 
                        then shake vigorously.</li>
                      <li>Add ice to your glass (preferably a high ball, but not required) 
                        and divide the mixture among the glasses. Top with a splash or two of club 
                        soda and stir in the Passion Fruit Juice/pulp. Top with Mint and Passion 
                        Fruit Seeds for garnish (can eat Passion Fruit Seeds)</li>
                    </ol>
                  </Col>
                </Row>
              </Container>
            </Jumbotron>
          </Col>
        </Row>
        <Row>
          <Col lg="6" md="12">
            <Jumbotron className="drink3">
              <h3>Frozen Mango Margarita Mocktail</h3>
              <Container>
                <Row>
                  <Col>
                    <img src={require("assets/img/SIMPLE-Mango-Chili-Lime-Margaritas.jpeg").default} alt="Frozen Mango Margarita Mocktail" />
                    <br /><br />
                    <h4>Ingredients</h4>
                    <h5>For the Mango Margarita:</h5>
                    <ul>
                      <li>2 cups (320 grams) Frozen Mango Chunks</li>
                      <li>3 ounces (88 ml) {" "}
                        <a href="https://www.ritualzeroproof.com/products/ritual-tequila-alternative" target="_ritual">Ritual Tequila Alternative</a>
                      </li>
                      <li>2 Limes juiced</li>
                      <li>1-2 tablespoons Agave Nectar or to taste</li>
                    </ul>
                    <h5>For the Chili-Salt Rim:</h5>
                    <ul>
                      <li>1 teaspoon Chili Powder</li>
                      <li>1/2 teaspoon Sea Salt</li>
                    </ul>
                    <h5>Garnish (optional):</h5>
                    <ul>
                      <li>Fresh Mint</li>
                      <li>Lime Wedges</li>
                    </ul>
                    <h4>Directions</h4>
                    <h5>Chili-Salt Rim:</h5>
                    <ol>
                      <li>On a small plate, mix the Chili Powder and Sea Salt. Rub 
                        a Lime Wedge around the edge of your glasses. Dip the 
                        rim in the Chili Powder/Sea Salt mixture and twist to coat. </li>
                    </ol>
                    <h5>Frozen Mango Margarita:</h5>
                    <ol>
                      <li>Place all the ingredients in a blender and blend until 
                        creamy and smooth.</li>
                      <li>Taste and, if desired, add a little more Tequila or 
                        Agave Nectar.</li>
                      <li>Pour the Margarita into the prepared glasses and garnish 
                        with a Lime Wedge and a sprig of Fresh Mint.</li>
                      <li>Serve immediately or transfer it to a container and 
                        store it in the freezer until needed.</li>
                    </ol>
                  </Col>
                </Row>
              </Container>
            </Jumbotron>
          </Col>
          <Col lg="6" md="12">
            <Jumbotron className="drink4">
              <h3>Blueberry, Basil, Lemon, and Lavender Mocktail</h3>
              <Container>
                <Row>
                  <Col>
                    <img src={require("assets/img/lrm_export_10799813648159_20190626_2156584341048891702.jpeg").default} alt="Blueberry, Basil, Lemon, and Lavender Mocktail" />
                    <br /><br />
                    <h4>Ingredients</h4>
                    <ul>
                      <li>1 pint Fresh Blueberries muddled (squished)</li>
                      <li>1 cup Basil chopped</li>
                      <li>½ cup Lemon Juice, freshly squeezed juice of about 2 lemons</li>
                      <li>¾ cup Lavender Simple Syrup</li>
                      <li>1½ cup Soda Water any unflavored carbonated beverage would work</li>
                    </ul>
                    <h5>Lavender Simple Syrup</h5>
                    <ul>
                      <li>1 cup Sugar</li>
                      <li>1 cup water</li>
                      <li>½ cup Lavender Buds (food grade)</li>
                    </ul>
                    <h4>Directions</h4>
                    <ol>
                      <li>In a medium sized pitcher add in the Blueberries, chopped 
                        Basil, and Lemon Juice.</li>
                        <li>Swish or muddle all the Bluerries, and Basil together 
                          until they relase their flavor and juices.</li>
                        <li>Add in the about 2 cups of Ice and the Soda Water, and 
                          stir thouroughly.</li>
                        <li>Pour. Garnish with Blueberries, Lemon, and Basil. Enjoy!</li>
                      </ol>
                      <h5>Lavender Simple Syrup</h5>
                      <ol>
                        <li>Add 1 cup of Sugar and 1 cup of Water to a small heavy 
                          bottomed sauce pan. Turn on medium heat. Stir 3-4x , then 
                          add in the Lavender Buds.</li>
                        <li>Let it heat until all the Sugar granules have dissolved. 
                          Then turn off the heat and allow the Lavender to steep and 
                          cool in the syrup until you are ready to use.</li>
                        <li>Use a funnel and a fine mesh strainer to get all the 
                          Lavenders Buds out of the syrup. You now have Lavender Simple 
                          Syrup for use for drinks, pancakes, cake making, in your tea 
                          or coffee.</li>
                    </ol>
                  </Col>
                </Row>
              </Container>
            </Jumbotron>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
